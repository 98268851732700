import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import {Paper} from "@material-ui/core";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Elmia from "./static/arboreal_elmia_web.jpg"
import TheGang from "./static/the gang_1.2.1_web.jpg"
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
  root: {
    maxHeight:2000
  },
  media: {
    width:"100%"
  },
});

const bigCards = [
  {
    image: TheGang,
    text: "Project with Vasakronan and A-Service Trä och Metall."
  },
  {
    image: Elmia,
    text: "We built the iOS app Arboreal Trädhöjd with our client Arboreal."
  }
]

export default function MediaCard() {
  const classes = useStyles();
  const cardIndex = Math.floor(Math.random() * 2);
  const [currentImage, setCurrentImage] = useState(bigCards[cardIndex])

  /*
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(currentImage => bigCards[Math.floor(Math.random() * 2)])
    }, 10000);
    return () => clearInterval(interval);
  }, [])
  */
  return (
    <Paper elevation={0} className={classes.root}>
      <img src={currentImage.image} className={classes.media}></img>

          <Typography variant="body2" color="textSecondary" component="p">
            {currentImage.text}
          </Typography>

    </Paper>
  );
}