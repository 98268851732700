import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import ButtonAppBar from "./button-app-bar.js"
import {Grid, Card, Paper, CardActionArea, CardContent} from "@material-ui/core"
import CardMedia from '@material-ui/core/CardMedia';
import MediaCard from "./mediacard"
import ClientGrid from "./clients.js"
import ReactGA from "react-ga"
ReactGA.initialize('UA-146437619-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
}));

const cardStyle = makeStyles((theme) => ({
  cardRoot: {
    maxWidth:900
  },
  media: {
    height:200,
    maxWidth:200 
  }
}))

export default function Home() {
    const classes = useStyles();

    return (
        <Grid container spacing={1} direction="column">
            <Grid item><ButtonAppBar></ButtonAppBar></Grid>
            <Grid item container>
                <Grid item xs={false} sm={2} />
                <Grid item xs={12} sm={8}><MediaCard /></Grid>
                <Grid item xs={false} sm={2} />
            </Grid>
            <Grid item container>
            <Grid item xs={false} sm={2} />
                <Grid item xs={12} sm={8}>
                  <Paper square elevation={0}>
                      <Typography variant="h3" component="p">
                      We build software and neural networks for clients.
                      </Typography>
                  </Paper>
                  </Grid>
                <Grid item xs={false} sm={2} />
            </Grid>
            <Grid item container>
                <Grid item xs={false} sm={2} />
                <Grid item xs={12} sm={8}><h1>Clients and partners</h1></Grid>
                <Grid item xs={false} sm={2} />
            </Grid>
            <ClientGrid></ClientGrid>
        </Grid>
    );
}