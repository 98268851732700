import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Grid} from "@material-ui/core"

import Novix from "./static/novix_logo.png"
import Vasakronan from "./static/vasakronan-logotyp_svart_web.png"
import Sveaskog from "./static/Sveaskog_Logotype_RGB_POS_web.png"
import Arboreal from "./static/AR-Arboreal-logo-turkos_120hog-1.png"
import ReactGA from "react-ga"
import Link from '@material-ui/core/Link';
ReactGA.initialize('UA-146437619-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const useStyles = makeStyles({
  root: {
    height:200
  },
  media: {
    height:100,
    backgroundSize:"contain"
  },
});

const clientCards = [
  {
    image: Vasakronan,
    text: ""
  },
  {
      image: Sveaskog,
      text:""
  },
  {
    image: Novix,
    text: ""
  },
  {
      image: Arboreal,
      text:""
  }
]



export default function ClientGrid() {
  const classes = useStyles();
  const clientGridItems = clientCards.map((client, i) => 
    <Grid item key={i} xs={12} sm={12}>
        <Card square className={classes.root}>
            <CardActionArea>
                <CardMedia
                className={classes.media}
                image={client.image}
                title="Contemplative Reptile"
                />
                <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {client.text}
                </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>
    );
  return (
    <Grid item container direction="column">
            {clientGridItems}
    </Grid>

  );
}