import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import ButtonAppBar from "./button-app-bar.js"
import {Grid, Card, Paper} from "@material-ui/core"
import MediaCard from "./mediacard"
import Home from "./home"
import About from "./about"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


export default function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

//ReactDOM.render(<App />, document.querySelector('#app'));
/*
<Grid container direction="column">
      <Grid item><ButtonAppBar></ButtonAppBar></Grid>
      <Grid item container>
        <Grid item xs={0} sm={2} />
        <Grid item xs={12} sm={8}><MediaCard /></Grid>
        <Grid item xs={0} sm={2} />
      </Grid>
      <Grid item container>
        <Grid item xs={2} sm={2} />
        <Grid item xs={12} sm={8} container direction="column">
          <Grid item><h1>Clients and partners</h1></Grid>
          <Grid item>Sveaskog</Grid>
          <Grid item>Vasakronan</Grid>
          <Grid item>Arboreal</Grid>
          <Grid item>Novix</Grid>
          <Grid item>A-Service Trä och Metall</Grid>
        </Grid>
        <Grid item xs={2} sm={2} />
      </Grid>

    </Grid>
*/
