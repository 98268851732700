import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import ButtonAppBar from "./button-app-bar.js"
import {Grid, Card, Paper} from "@material-ui/core"
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Samuel from "./static/board/sam_wood_3.png"
import Asa from "./static/board/asa_first_square_web.jpg"
import Olof from "./static/board/Profile-squared_web.jpg"
import Jesper from "./static/board/JesperSquare_web.jpg"
import Link from '@material-ui/core/Link';

/*height: 250,*/
const useStyles = makeStyles({
    media: {
        height:200,
    },
  });
  
export default function About() {
    const classes = useStyles();

    return (
        <Grid container spacing={1} direction="column">
            <Grid item><ButtonAppBar></ButtonAppBar></Grid>
            <Grid item container spacing={1}>
                <Grid item xs={0} sm={2} />
                <Grid item xs={12} sm={2}>
                    <Card elevation={3}>
                        <CardMedia
                            className={classes.media}
                            image={Samuel}
                            title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Samuel Norling<br></br>samuel@salaguno.com
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Card elevation={3}>
                        <CardMedia
                            className={classes.media}
                            image={Asa}
                            title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Åsa Linder<br></br>
                                asa@salaguno.com
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Card elevation={3}>
                        <CardMedia
                            className={classes.media}
                            image={Olof}
                            title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Olof Lindman<br></br>olof@salaguno.com
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Card elevation={3}>
                        <CardMedia
                            className={classes.media}
                            image={Jesper}
                            title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Jesper Sundin<br></br>jesper@salaguno.com
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={0} sm={2} />
            </Grid>
            <br></br>
            <Grid item container>
                <Grid item xs={false} sm={2}></Grid>
                <Grid item container direction="column" sm={8}>
                    <Grid item>
                        <Typography variant="h3">
                        In the press
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography><Link href="https://www.landskogsbruk.se/skog/ny-app-mater-skogen-digitalt/">https://www.landskogsbruk.se/skog/ny-app-mater-skogen-digitalt/</Link></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={false} sm={2}></Grid>
            </Grid>

        </Grid>
    );
}