import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import SalagunoLogo from "./static/Salaguno_Logo_Small-5.png"

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
}));

const logoStyles = makeStyles((themes) => ({
  logoMedia: {
    maxWidth: 50
  }
}));

const linkStyles = makeStyles((themes) => ({
  title: {
    color:"black"
  }
}));
  
export default function ButtonAppBar() {
    const classes = useStyles();
    const logoClasses = logoStyles();
    const linkClasses = linkStyles();
    return (
      <AppBar color="transparent" position="static">
      <Toolbar>
          <img className={logoClasses.logoMedia} src={SalagunoLogo}></img>
          <Typography variant="inherit" className={classes.title}>
            <Link className={linkClasses.title} href="/">Salaguno</Link>
          </Typography>
          <Button color="inherit" href="./about">About us</Button>
      </Toolbar>
      </AppBar>
    );
}